/* Card CSS  */

/*.ui-card {*/
/*    !* background: var(--ic-theme-bg); *!*/
/*    padding: 35px;*/
/*    border-radius: 10px;*/
/*    box-shadow: 0 40px 120px -40px rgba(46, 49, 146, .363);*/
/*    border: 1px solid var(--ic-border);*/
/*    transition: .4s;*/
/*    margin-bottom: 25px;*/
/*}*/

.ui-card {
    background: #fff;
    box-shadow: 0 48px 120px -40px rgba(46, 49, 146, .106);
    border-radius: 5px;
    position: relative;
    padding: 30px;
    margin-bottom: 20px;
}

.ui-card:hover {
    box-shadow: 0 48px 120px -40px rgba(46, 49, 146, .106);
    transition: .4s;
    cursor: grab;
}

.ui-card i {
    background: var(--ic-theme-blue);
    display: inline-block;
    font-size: 22px;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    color: var(--ic-white);
}

.ui-card a {
    text-transform: uppercase;
    color: var(--ic-text);
    border: 1px solid var(--ic-border);
    display: block;
    height: 40px;
    width: 110px;
    text-align: center;
    line-height: 40px;
    border-radius: 100px;
    font-size: 14px;
}

.ui-card img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.ui-card h3 {
    font-weight: 700;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.chosseUs-card p {
    color: var(--ic-text);
    margin: 0;
}

/* Card CSS End */

/* Testimonials  */

/*.Testimonial-Card {*/
/*    background: #fff;*/
/*    padding: 30px;*/
/*    transition: .4s;*/
/*    border-radius: 10px;*/
/*    margin-bottom: 25px;*/
/*    border: 1px solid var(--ic-border);*/
/*}*/
.Testimonial-Card {
    background: #fff;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.00);
    border-radius: 5px;
    position: relative;
    padding: 30px;
    margin-bottom: 25px;
}

.Testimonial-Card:hover {
    box-shadow: 0 48px 120px -40px rgba(46, 49, 146, .106);
    transition: .4s;
    cursor: grab;
}

.Testimonial-Card i {
    font-size: 50px;
    font-weight: 800;
    color: var(--ic-theme-blue);
}

.Testimonial-Card p {
    font-size: 17px;
    color: var(--ic-text);
    line-height: 27px;
    text-align: left;
    margin-bottom: 20px;
}

.Testimonial-Card h4 {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
}

.Testimonial-Card span {
    font-size: 14px;
    color: var(--ic-text);
}

/* Testimonials End  */


/* Customar  */
.customar-images img {
    /* max-width: 60%; */
    max-width: 90%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
}

.customar-images img:hover {
    transform: scale(1.15);
    cursor: grab;
}

/* Customar End */

/* portfolio  */
.portfolio-item-wrap {
    border: 1px solid var(--ic-border);
    border-radius: 5px;
    position: relative;
}

.portfolio-item-wrap:hover .portfolio-item-text {
    opacity: 1;
    transition: .4s;
}

.portfolio-item-wrap img {
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    width: 100%;
    height: 370px;
}

.portfolio-item-wrap img:hover {
    box-shadow: 0 40px 120px -40px rgba(46, 49, 146, 0.950);
    /* transform: scale(1.05); */
    cursor: grab
}



.portfolio-item-text h3 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: var(--ic-white);
}

.portfolio-item-text {
    opacity: 0;
    padding: 15px;
    position: absolute;
    bottom: 0px;
    left: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: rgba(46, 49, 146, .663);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .4s;
    cursor: grab;
}

.portfolio-item-text p {
    margin-top: 5px;
    margin-bottom: 0;
    color: var(--ic-white);
    font-weight: 600;
}

/* portfolio End */

/* Team  */

.team-single-item {
    text-align: center;
    margin-bottom: 25px;
}

.team-single-item img {
    width: 100%;
    border-radius: 7px;
}

.team-single-item h3 {
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 600;
    color: var(--ic-heading);
    font-size: 25px;
}

.team-single-item p {
    margin: 0;
    font-weight: 600;
    color: var(--ic-text);
    margin-top: 6px;
}

/* Team End */

/* Section title  */
.section-title-wrap h4 {
    color: var(--ic-theme-blue);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0;
}

/*.section-title-wrap h1 {*/
/*    font-weight: 800;*/
/*    margin: 15px 0;*/
/*    text-transform: capitalize;*/
/*    color: var(--ic-heading);*/
/*}*/

.section-title-wrap h2 {
    box-sizing: content-box;
    aspect-ratio: auto;
    color: #101010;
    font-size: 61px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 106%;
}

.section-title-wrap p {
    margin: 0;
    font-size: 17px;
    color: var(--ic-text);
}

/* Section title End*/

/* Services Details  */

.servies-details-title {
    font-weight: 600;
}

.services-details-content p {
    line-height: 26px;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    .section-title-wrap h2 {
        font-size: 35px;
    }
}