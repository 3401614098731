.breadcrumbs-section-wrap p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ic-text);
    margin-bottom: 5px;
}

.breadcrumbs-section-wrap h3 {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ic-heading);
}



.breadcrumbs-section-wrap {
    background: var(--ic-hero-bg);
    padding: 100px 0;
}