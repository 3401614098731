.cta-area-wrap {
    background: linear-gradient(145deg, var(--ic-theme-blue) 0%, var(--ic-theme-red) 100%);
    /* background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%); */
    position: relative;
    z-index: 2;
    padding: 6rem;
}

.cta-area-wrap::before {
    background-image: url('../../img/cta.png');
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    z-index: -1;
    opacity: 0.9;
}

.cta-text p {
    color: var(--ic-white);
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: block;
    margin-bottom: 9px;
}

.cta-text h1 {
    color: var(--ic-white);
    font-size: 75px;
    font-weight: 900;
    line-height: 90px;
    font-family: "Montserrat",
    sans-serif;
}

.call-now {
    color: var(--ic-white);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid var(--ic-white);
    padding: 15px 40px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: 0.3s;
    margin-top: 25px;
}

.call-now:hover {
    background: var(--ic-white);
    border-color: var(--ic-white);
    color: var(--ic-theme-red);
}