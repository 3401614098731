.f-logo img {
    width: 160px;
}

.footer-wrap {
    background: url('../../img/footer-bg.png');
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-color: #01070e;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.f-desc p {
    color: #c7c7c7;
    margin-bottom: 28px;
    margin-top: 30px;
}

.f-title {
    color: var(--ic-white);
    line-height: 26px;
    margin-bottom: 33px;
    font-weight: 600;
}

.f-social-icons ul li a {
    background: var(--ic-theme-blue);
    margin-right: 10px;
    padding: 5px;
    font-size: 18px;
    border-radius: 50px;
    width: 40px !important;
    display: inline-block;
    height: 40px;
    text-align: center;
    color: var(--ic-white);
    transition: .4s;
}

.f-social-icons ul li a:hover {
    background: var(--ic-white);
    color: var(--ic-theme-blue);
    transition: .4s;
}

.f-menu {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px
}

.f-menu li {
    position: relative;
    transition: all .3s ease-in-out 0s
}

.f-menu li a {
    color: #c7c7c7
}

.f-menu li:before {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .3s ease-in-out 0s;
    width: 0
}

.f-menu li:hover {
    padding-left: 15px
}

.f-menu li:hover:before {
    width: 12px
}

.f-menu li:hover a {
    color: var(--ic-theme-blue)
}

.footer-cr {
    border-top: 1px solid #333;
    padding: 20px 0;
    margin-top: 30px;
}

.footer-cr .footer-cr-container {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.footer-cr .footer-cr-container * {
    color: #c7c7c7
}

.footer-cr .footer-cr-container .p-left p {
    margin: 0
}

.footer-cr .footer-cr-container ul.cr-menu {
    display: flex
}

.footer-cr .footer-cr-container ul.cr-menu li {
    border-right: 1px solid #c7c7c7;
    padding: 0 30px;
    transition: all .1s ease-in-out 0s
}

.footer-cr .footer-cr-container ul.cr-menu li:hover a {
    color: var(--ic-theme-blue)
}

.footer-cr .footer-cr-container ul.cr-menu li:last-child {
    border: 0;
    margin-right: 0
}
section#shadow-host-companion {
    display: none;
}

