.hero-area-wrap {
    background: #f7f8f8;
    padding: 100px 20%;
}


.hero-area-wrap h1 {
    box-sizing: content-box;
    aspect-ratio: auto;
    color: #444345;
    font-size: 67px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 60px;
    line-height: 121%;
}

.hero-ribbon-text {
    position: relative;
    display: inline-block !important;
    margin-left: 15px;
}

.hero-ribbon-text::before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 7px;
    width: 100% !important;
    border: solid 8px #101010;
    border-color: #101010 transparent transparent transparent;
    border-radius: 50%;
}

.hero-area-wrap p {
    color: #101010;
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 60px;
}


@-webkit-keyframes playAnimition {
    0% {
        box-shadow: 0 0 0 0 rgba(46, 49, 146, .663)
    }

    to {
        box-shadow: 0 0 0 25px rgba(46, 49, 146, .106)
    }
}

@keyframes playAnimition {
    0% {
        box-shadow: 0 0 0 0 rgba(46, 49, 146, .663)
    }

    to {
        box-shadow: 0 0 0 25px rgba(46, 49, 146, .106)
    }
}

.play-btn {
    -webkit-animation: playAnimition 1.5s linear 0s infinite;
    animation: playAnimition 1.5s linear 0s infinite;
    background: var(--ic-theme-blue);
    border: none;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    left: 0;
    position: relative;
    text-align: center;
    top: 0;
    -webkit-transform: none;
    transform: none;
    cursor: pointer;
    font-size: 32px;
}

.btn-group {
    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: flex-start;
}

.btn {
    border: none;
    border-radius: 30px;
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    line-height: 1;
    padding: 20px 24px;
}

.btn-1 {
    background-color: var(--ic-theme-blue);
    color: #fff;
    text-decoration: none;
    padding: 15px 40px;
    border-radius: 50px;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    transition: .4s;
}

.btn-1:hover {
    color: #fff;
    background-color: rgba(46, 49, 146, .663);
    transition: .4s;
}


/* Section Bg  */

/* .testimonials-wrap,
.what-we-do-wrap,
.why-chosse-us-wrap {
    background-color: var(--ic-theme-bg);
} */

/* .our-project,
.our-customers-wrap,
.skilled-team-wrap {
    background-color: var(--ic-theme-bg-2);
} */

@media only screen and (max-width: 768px) {
    .hero-area-wrap {
        padding: 50px 60px !important;
    }
    .hero-area-wrap h1 {
        font-size: 35px;
    }
}
@media only screen and (max-width: 767px) {
    .hero-area-wrap {
        padding: 50px 20px !important;
    }
}
