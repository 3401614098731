.contactUs-wrap,
.contactUs-form {
    background: var(--ic-theme-bg) !important;
}


/*.contact-header-item {*/
/*    margin-top: 30px;*/
/*    border: 1px solid var(--ic-border);*/
/*    padding: 30px;*/
/*    border-radius: 10px;*/
/*    background: var(--ic-white);*/
/*    min-height: 160px;*/
/*}*/

.contact-header-item {
    background: #fff;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.00);
    border-radius: 5px;
    position: relative;
    padding: 30px;
    margin-bottom: 25px;
    min-height: 150px;
}

.contact-header-item:hover {
    box-shadow: 0 48px 120px -40px rgba(46, 49, 146, .106);
    transition: .4s;
    cursor: grab;
}

.contact-header-item a,
.contact-header-item p {
    display: block;
    font-size: 18px;
    margin-bottom: 0;
    color: rgba(29, 29, 36, 0.75);
    margin: 5px 0;
}

.contact-header-title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
}

.form-input {
    padding: 15px !important;
    margin-bottom: 20px;
}

.form-input:focus {
    box-shadow: none !important;
}