/* .about-who-we-are,
.skilled-team-wrap {
    background-color: var(--ic-theme-bg);
} */

.our-fun-fact {
    background-color: var(--ic-theme-bg-2);
}

.about-who-we-are h1 {
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--ic-heading);
}

.about-who-we-are p {
    font-size: 18px;
    line-height: 30px;
    color: var(--ic-text);
}

.about-who-we-are img {
    border-radius: 10px;
    box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
    transition: .4s;
}

.about-who-we-are img:hover {
    box-shadow: 0 40px 120px -40px rgba(46, 49, 146, .600);
    transition: .4s;
}

.about-who-we-are h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--ic-heading);
}

.counter-count {
    font-size: 62px;
    line-height: 80px;
    position: relative;
    display: inline-block;
    color: var(--ic-theme-red);
    margin-left: -13px;
    font-weight: 700;
}

.counter-count:after {
    font-weight: 700;
    right: -26px;
    position: absolute;
    top: -17px;
    font-size: 40px;
    content: "+";
}

.counter-title {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ic-heading);
}

.description {
    margin-top: 15px;
    font-size: 18px;
    line-height: 30px;
    padding: 0 5%;
    color: rgba(29, 29, 36, 0.75);
}