:root {
  --ic-border: #32359524;
  --ic-dark: #434244;
  --ic-light: #fafafb;
  --ic-white: #fff;
  --ic-black: #000;
  --ic-heading: #1c1c1c;
  --ic-text: #535353;
  --ic-theme-bg: #F7F8F8;
  --ic-theme-bg-2: #EEF2F7;
  --ic-theme-blue: #2e3192;
  --ic-theme-red: #E61A21;
  --ic-light-primary: #f5fbff;
  --ic-light-secondary: #f1f2f1;
  --ic-hero-bg: #ECF5FF;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body {
  font-family: 'Poppins',
    sans-serif;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0 !important;
  padding: 0 !important;
}

ul li {
  list-style: none;
}

section {
  padding: 3rem 0;
}

.padding-13 {
  padding: 13px 0;
}

.ic-primary-button {
  z-index: 6;
  border-radius: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;
  text-decoration: none;
  display: flex;
  position: relative;
  background: var(--ic-theme-blue);
  color: #ffffff;
}
.ic-primary-button:hover {
  background: var(--ic-theme-blue);
  color: #ffffff;
}



/* View More btn  */
.view-more-btn {
  width: 250px !important;
  text-align: center;
  margin: 0 auto;
  padding: 15px;
  border-radius: 6px;
  border: 2px solid var(--ic-theme-blue);
  background: var(--ic-theme-blue);
  color: var(--ic-white);
  transition: .4s;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 600;
  margin-top: 30px !important;
}

.view-more-btn:hover {
  background: transparent;
  color: var(--ic-theme-blue);
  box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
  transform: translateY(-5px);
  transition: .4s;
}

/* View More btn  End*/


/* Responsive CSS  */
@media screen and (min-width: 320px) and (max-width:480px) {
  .navbar-brand img {
    width: 160px !important;
    margin-left: -10px;
  }

  .section-sub-title {
    font-size: 15px !important;
  }

  .section-main-title {
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }

  .hero-area-wrap img {
    display: none;
  }

  .hero-text {
    margin-bottom: 30px !important;
  }

  section {
    padding: 1.5rem 0 !important;
  }

  .section-title-wrap h1 {
    font-size: 24px;
  }

  .ui-card {
    padding: 20px !important;
    border-radius: 5px !important;
    margin-bottom: 15px !important;
    text-align: center !important;
  }

  .our-customers-wrap {
    text-align: center;
  }

  .cta-text h1 {
    font-size: 25px !important;
    line-height: 40px !important;
  }

  .call-now {
    margin-top: 15px !important;
  }

  .f-social-icons {
    margin-bottom: 30px;
  }

  .f-menu {
    margin-bottom: 30px !important;
  }

  .footer-cr .footer-cr-container {
    flex-direction: column !important;
  }

  .p-left {
    text-align: center;
  }

  .p-right {
    margin-top: 20px;
  }

  .footer-cr .footer-cr-container ul.cr-menu {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .footer-cr .footer-cr-container ul.cr-menu li {
    border-right: 0 !important;
  }

  .project-item-filter {
    flex-wrap: wrap;
  }

  .breadcrumbs-section-wrap {
    background-position: inherit !important;
    background-size: contain !important;
    padding: 50px 0 !important;
  }

  .navbar-nav {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .portfolio-item-wrap img {
    height: 330px !important;
  }
}

@media screen and (min-width: 768px) and (max-width:1024px) {
  .navbar-brand img {
    width: 160px !important;
    margin-left: -10px;
  }

  .section-sub-title {
    font-size: 15px !important;
  }

  .section-main-title {
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }

  .portfolio-item-wrap img {
    height: 230px !important;
  }

  .hero-text {
    margin-bottom: 30px !important;
  }

  section {
    padding: 1.5rem 0 !important;
  }

  .section-title-wrap h1 {
    font-size: 24px;
  }

  .ui-card {
    padding: 20px !important;
    border-radius: 5px !important;
    margin-bottom: 15px !important;
    text-align: center !important;
  }

  .our-customers-wrap {
    text-align: center;
  }

  .cta-text h1 {
    font-size: 25px !important;
    line-height: 40px !important;
  }

  .call-now {
    margin-top: 15px !important;
  }

  .f-social-icons {
    margin-bottom: 30px;
  }

  .f-menu {
    margin-bottom: 30px !important;
  }

  .footer-cr .footer-cr-container {
    flex-direction: column !important;
  }

  .p-left {
    text-align: center;
  }

  .p-right {
    margin-top: 20px;
  }

  .footer-cr .footer-cr-container ul.cr-menu {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .footer-cr .footer-cr-container ul.cr-menu li {
    border-right: 0 !important;
  }

  .project-item-filter {
    flex-wrap: wrap;
  }

  .breadcrumbs-section-wrap {
    background-position: inherit !important;
    background-size: contain !important;
    padding: 50px 0 !important;
  }

  .navbar-nav {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}



#generic_price_table{
  background-color: #f0eded;
}

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content{
  background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price{
  background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg{
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span{
  color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign{
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency{
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent{
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month{
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li{
  color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span{
  color: var(--ic-theme-blue);
}
#generic_price_table .generic_content .generic_feature_list ul li:hover{
  background-color: #dddddd;
  border-left: 5px solid var(--ic-theme-blue);
}

#generic_price_table .generic_content .generic_price_btn button{
  border: 1px solid var(--ic-theme-blue);
  color: var(--ic-theme-blue);
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg{
  border-color: var(--ic-theme-blue) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--ic-theme-blue);
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span{
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn button,
#generic_price_table .generic_content.active .generic_price_btn button{
  background-color: var(--ic-theme-blue);
  color: #fff;
}
#generic_price_table{
  margin: 50px 0 50px 0;
}
.row .table{
  padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content{
  overflow: hidden;
  position: relative;
  text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content{
  margin: 0 0 50px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg{
  border-style: solid;
  border-width: 90px 1411px 23px 399px;
  position: absolute;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head{
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span{
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag{
  padding: 0 0 20px;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price{
  display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign{
  display: inline-block;
  font-size: 28px;
  font-weight: 400;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency{
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 60px;
  padding: 0;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent{
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month{
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li{
  font-size: 18px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li:hover{
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;

}
#generic_price_table .generic_content .generic_feature_list ul li .fa{
  padding: 0 10px;
}
#generic_price_table .generic_content .generic_price_btn{
  margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn button{
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-size: 18px;
  outline: medium none;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn button,
#generic_price_table .generic_content:hover .generic_price_btn button{
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
@media (max-width: 320px) {
}

@media (max-width: 767px) {
  #generic_price_table .generic_content{
    margin-bottom:75px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table .col-md-3{
    float:left;
    width:50%;
  }

  #generic_price_table .col-md-4{
    float:left;
    width:50%;
  }

  #generic_price_table .generic_content{
    margin-bottom:75px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) {
}
#generic_price_table_home{
  font-family: 'Poppins', sans-serif;
}

.text-center h1,
.text-center h1 a{
  color: #7885CB;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;
}
.demo-pic{
  margin: 0 auto;
}
.demo-pic:hover{
  opacity: 0.7;
}

#generic_price_table_home ul{
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table;
}
#generic_price_table_home li{
  float: left;
}
#generic_price_table_home li + li{
  margin-left: 10px;
  padding-bottom: 10px;
}
#generic_price_table_home li a{
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0px;
}
#generic_price_table_home .blue{
  background: #3498DB;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .emerald{
  background: #2ECC71;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .grey{
  background: #7F8C8D;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .midnight{
  background: #34495E;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .orange{
  background: #E67E22;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .purple{
  background: #9B59B6;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .red{
  background: #E74C3C;
  transition:all 0.3s ease-in-out 0s;
}
#generic_price_table_home .turquoise{
  background: #1ABC9C;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover{
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .divider{
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}
#generic_price_table_home .divider span{
  width: 100%;
  display: table;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  line-height: 2px;
}
#generic_price_table_home .itemname{
  text-align: center;
  font-size: 50px ;
  padding: 50px 0 20px ;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  text-decoration: none;
  font-weight: 300;
}
#generic_price_table_home .itemnametext{
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
  text-transform: uppercase;
  display: inline-block;
}
#generic_price_table_home .footer{
  padding:40px 0;
}

.price-heading{
  text-align: center;
}
.price-heading h1{
  color: #666;
  margin: 0;
  padding: 0 0 50px 0;
}
.demo-button {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  outline-color: -moz-use-text-color;
  outline-style: none;
  outline-width: medium ;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}
.bottom_btn{
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 28px;
  margin: 60px auto 20px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
}
.demo-button:hover{
  background-color: #666;
  color: #FFF;
  text-decoration:none;

}
.bottom_btn:hover{
  background-color: #666;
  color: #FFF;
  text-decoration:none;
}

.error-message {
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  color: red;
}


Order success component design
.order-success-page {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
  vertical-align: middle;
}

.card-success {
  background: white;
  padding: 60px;
  border-radius: 4px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.card-success .success-h2 {
  color: #5411ef;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.card-success p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}


.success-checkmark {
  border-radius: 200px;
  height: 150px;
  width: 150px;
  background: #5411ef;
  margin: 0 auto;
  text-align: center;
}

.success-checkmark i {
  color: #ffffff;
  font-size: 100px;
  line-height: 150px;
  margin-left: -15px;
}