/* .our-project {
    background: var(--ic-theme-bg) !important;
} */

.project-item-filter {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-btn.active,
.filter-btn:hover {
    background-color: var(--ic-white);
    box-shadow: 0px 10px 30px 0px rgba(89, 86, 233, 0.2);
    padding: 7px 12px;
}


.filter-btn {
    background-color: transparent;
    transition: .3s;
    border: 0;
    padding: 7px 12px;
    font-weight: 700;
    border-radius: 5px;
    color: #444345;
    font-size: 14px;
}


.portfolio-item-wrap {
    -o-transition: all .4s;
    transition: all .4s;
}



/* */