.header-menu {

}
.navbar-brand img {
    width: 220px;
}

.navbar-nav a {
    color: var(--ic-black);
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 10px !important;
}

.header-menu {
    padding: 20px !important;
    border-bottom: 1px solid var(--ic-border);
    background-color: #ECF5FF;
}